



















































































































































































































































































































































































































































































































































































































































@media print {
  .main-panel .footer {
    display: none;
  }
  .main-panel .content {
    padding: 10px;
  }
}
